@import './fonts';
@import './colors';
@import '../breakpoints';
@import './icons';
@import '../common';

body {
    font-family: $proxima-nova-regular;
    margin: 0 auto;
    color: $brand-black;
    max-width: 1440px;
}
.lincoln-na-header {
    max-width: 1440px;
    .hidden-xs {
        display: block !important;
    }

    .no-scroll-sm {
        overflow-y: hidden !important;
        position: relative;
        height: 100%;
    }
    .lincoln-na-header-overlay {
        display: none;
    }

    .lincoln-na-header-overlay.open {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background-color: $brand-secondary;
    }

    @media screen and (max-width: $mobile-width) {
        .hidden-xs {
            display: none !important;
        }
    }
}
