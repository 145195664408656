@import '../../../../../styles/lincoln/main.scss';

.brand-lincoln {
    .confirm-rebates-offer-print {
        display: none;
        @media print {
            display: initial;
        }

        .confirm-rebates-print {
            display: none;
        }

        .confirm-rewards-point {
            text-align: center;
        }

        .confirm-rebates-row {
            display: flex;
            justify-content: center;
        }

        .confirm-rebates-elementals {
            padding: 2rem 2rem 0 0;
            .confirm-rebate-status {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                text-transform: uppercase;
                font-size: 17px;
                letter-spacing: 2px;
                line-height: 27px;
            }
            .confirm-rebate-response {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 24px;
            }
        }
    }
    .entire-confirm-rebate-status-response {
        max-width: 1440px;
        margin: 0 auto;
        .confirm-rebate-status-response {
            padding: 2rem;
            max-width: 102.4rem;
            background-color: $white;

            @include mobile {
                margin: auto;
            }

            @include tablet {
                margin: auto;
            }

            @include desktop {
                margin: auto 10rem;
            }

            .confirm-header-text {
                text-transform: uppercase;
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                font-size: 24px;
                font-weight: 300;
                text-align: center;
                margin: 2rem 0;
                letter-spacing: 2.5px;
                line-height: 32px;
            }
            .confirm-rewards-point {
                color: $brand-secondary;
                font-family: $proxima-nova-bold;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
            }
            .confirm-rebates-row {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
            .confirm-rebate-status {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                text-transform: uppercase;
                font-size: 17px;
                letter-spacing: 2px;
                line-height: 27px;
            }
            .confirm-rebate-response {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 24px;
            }
            .confirm-rebatesRes-elements {
                padding: 2rem 0;
            }

            hr {
                box-sizing: border-box;
                border: 1px solid $fds-color-border-md-gray;
                box-shadow: none;
            }

            .confirm-rebates-content {
                padding: 2rem 0;
            }

            .confirm-rebates-elementals {
                padding: 0 3rem 0 0;
            }

            .confirm-rebate-response-button {
                display: flex;
                justify-content: flex-end;

                @include mobile {
                    justify-content: center;
                }
            }

            .confirm-rebates-print {
                text-align: right;
                .confirm-print-button {
                    background-color: $white;
                    border: none;
                    box-shadow: none;
                    &:hover {
                        transform: scale(1.07);
                        cursor: pointer;
                    }
                }
            }

            @media print {
                display: none;
            }
        }
    }
}
