.brand-lincoln {
    .review_rebates_form {
        margin: 0;
        align-content: center;
        display: block;
        position: relative;
        padding: 1rem 0 3rem 0;
        margin-left: 18px;
        margin-right: 18px;
        section {
            border-bottom: 1px solid $fds-color-border-md-gray;
        }
        @include tablet {
            padding: 0 2rem;
        }
        @include desktop {
            margin-left: 8%;
            margin-right: 8%;
        }
        .data_row_field_info {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: 17px;
            letter-spacing: 2px;
            line-height: 27px;
            margin: 0px 100px 41px 10px;
            @include desktop {
                margin: 65px 100px 41px 10px;
            }
            @include md-desktop {
                margin: 65px 99px 41px 10px;
            }
            .heading {
                text-transform: uppercase;
            }
            div {
                font-size: 18px;
                letter-spacing: 0;
                line-height: 24px;
                margin-top: 5px;
            }
        }
        .data_row_field_info_img {
            color: $dark-blue;
            font-family: $f1-font-light;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 26px;
            margin: 40px 0px 26px 20px;
            @include desktop {
                margin: 62px 54px 15px 10px;
            }
            .vehicle-image {
                display: flex;
                pointer-events: none;
                justify-content: left;
                height: 87px;
            }
        }
        .vehicle_data_row {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 41px;
            @include desktop {
                display: flex;
                flex-direction: row;
            }
        }
        .dealer_data_row {
            display: flex;
            flex-direction: column;

            @include desktop {
                display: flex;
                flex-direction: row;
            }
            .dealer_data_col {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 41px;
                .dealer_col_heading {
                    height: 30px;
                    width: 240px;
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                    font-size: 17px;
                    letter-spacing: 2px;
                    line-height: 27px;
                    text-transform: uppercase;
                }
                .dealer_col_info {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                    font-size: 18px;
                    letter-spacing: 0;
                    line-height: 24px;
                }
            }
            .edit-button {
                margin-bottom: 32px;
                @include desktop {
                    margin-left: 27rem;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .primary-button {
                    box-sizing: border-box;
                    border: 2px solid $brand-secondary;
                    @include desktop {
                        right: 10rem;
                    }
                }
            }
        }
        .rebate_data_row {
            display: block;
            margin-top: 70px;
            .rebate_data_col {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 41px;
                @include desktop {
                    margin-top: 0;
                }
                .rebate_col_heading {
                    height: 30px;
                    width: 240px;
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                    font-size: 17px;
                    letter-spacing: 2px;
                    line-height: 27px;
                    text-transform: uppercase;
                }
                .rebate_col_info {
                    height: 78px;
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                    font-size: 18px;
                    letter-spacing: 1px;
                    line-height: 26px;
                    .rebate_group_name {
                        font-weight: bold;
                    }
                }
            }
            .rebate-legal-regular {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
                font-size: 11px;
                line-height: 13px;
                letter-spacing: 0;
                -webkit-font-smoothing: antialiased;
                text-align: left;
                .rebate-disclaimer-text {
                    margin-top: 8px;
                    margin-bottom: 2rem;
                    text-align: justify;
                }
            }
        }

        .owner_info_data_row {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            flex: 1 1;
            width: 100%;
            margin-bottom: 52px;
            border-bottom: none;

            @include desktop {
                display: flex;
                flex-direction: row;
            }
            .owner_personal_info_data {
                display: flex;
                flex-direction: column;
                .owner_personal_details {
                    display: flex;
                    flex-direction: column;

                    @include desktop {
                        display: flex;
                        flex-direction: row;
                    }
                    .personal_data_row_field_info {
                        font-family: $proxima-nova-regular;
                        font-size: 18px;
                        letter-spacing: 1px;
                        line-height: 26px;
                        margin: 20px 50px 41px 0px;
                        height: 30px;
                        width: 304.14px;
                        color: $brand-secondary;
                        .heading {
                            color: $brand-secondary;
                            font-family: $proxima-nova-regular;
                            font-size: 17px;
                            letter-spacing: 2px;
                            line-height: 27px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
        .rebate-edit-button {
            display: none;
            @include desktop {
                display: block;
                margin-top: 0;
                margin-bottom: 0;
                position: absolute;
                right: 4%;
            }
            @include md-desktop {
                right: 3%;
            }
            @include xl-desktop {
                right: 2%;
            }
            .primary-button {
                box-sizing: border-box;
                border: 2px solid $brand-secondary;
                @include desktop {
                    right: 10rem;
                }
            }
        }
        .rebate-edit-button-mobile-view {
            display: block;
            margin-bottom: 32px;
            @include desktop {
                display: none;
            }
            .primary-button {
                box-sizing: border-box;
                border: 2px solid $brand-secondary;
            }
        }
        .owner-info-edit-button {
            margin-top: 25px;
            margin-bottom: 20px;
            @include desktop {
                margin-top: 0;
                position: absolute;
                right: 4%;
            }
            @include md-desktop {
                right: 3%;
            }
            @include xl-desktop {
                right: 2%;
            }
            .primary-button {
                box-sizing: border-box;
                border: 2px solid $brand-secondary;
                @include desktop {
                    right: 10rem;
                }
            }
        }
        .submit-button {
            margin-bottom: 3em;
            display: flex;
            justify-content: center;
            @include tablet {
                display: block;
                padding-left: 40%;
            }
            @include desktop {
                display: flex;
                margin-top: 70px;
                padding-left: 91%;
            }
            @media (min-width: 1180px) {
                padding-left: 93%;
            }
            @include xl-desktop {
                padding-left: 95%;
            }
        }
    }
}
