.brand-ford {
    .form-finalize {
        margin: auto auto;
        max-width: 102.4rem;
        padding: 1rem 1rem 5rem;

        @include tablet {
            padding: 1rem 1rem 5rem;
        }

        @include desktop {
            padding: 1rem 8rem 5rem;
        }

        .finalize-download {
            padding: 1rem 0;
            margin: 1rem 0;
            text-align: center;

            .finalize-form-text {
                font-size: 16px;
                line-height: 26px;
                font-family: $f1-font-regular;
                color: $fds-color-dk-gray;
                margin: 1rem 0;
            }
            .finalize-download-text-link {
                font-size: 18px;
                line-height: 26px;
                color: $dark-blue;
                font-family: $f1-font-regular;
                margin: 1rem 0;
                .finalize-download-link {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .finalize-Mail-Rebate-Forms {
                .finalize-download-header {
                    text-align: center;
                    font-family: $f1-font-regular;
                    font-size: 18px;
                    line-height: 26px;
                    color: $fds-color-dk-gray;
                }
                .finalize-download-text {
                    text-align: center;
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 26px;
                    padding: 1rem 1rem;
                    @include tablet {
                        padding: 1rem 1rem;
                    }

                    @include desktop {
                        padding: 0.5rem 20%;
                    }
                }
                .finalize-download-form {
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: left;
                    padding: 1.5rem;
                    .finalize-form-link {
                        color: $fds-color-secondary;
                        cursor: pointer;
                    }
                }
                .line {
                    box-sizing: border-box;
                    border: 1px solid $fds-color-border-md-gray;
                    box-shadow: none;
                    width: 96%;
                    &.hide {
                        display: none;
                    }
                }
            }
        }
        hr {
            box-sizing: border-box;
            border: 1px solid $fds-color-border-md-gray;
            box-shadow: none;
            width: 96%;
        }
        .finalize-member-rebates-row {
            display: flex;
            flex-direction: column-reverse;

            @include tablet {
                flex-direction: row;
                padding: 1.5rem;
            }

            @include desktop {
                flex-direction: row;
                padding: 1.5rem;
            }

            .finalize-signin-memberid {
                padding: 1.5rem;
                color: $dark-blue;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 28px;
                @include desktop {
                    padding: 1.5rem 0;
                }
                .finalize-signin-memberid-text {
                    color: $fds-color-dk-gray;
                }
            }

            .row-elements {
                flex: 2;
                .form__div {
                    position: relative;
                    @include tablet {
                        padding-right: 20%;
                    }
                    @include desktop {
                        padding-right: 30%;
                    }
                    .form__input {
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 26px;
                        color: $fds-color-primary;
                        padding: 15px;
                        width: 100%;
                        height: 50px;
                        border-radius: 0.2rem;
                        border: 0.1rem solid $fds-color-md-gray;
                        transition: all 0.3s ease-in-out;
                        &:focus {
                            box-shadow: $outer-drop-shadow;
                            border: 1.5px solid $black;
                        }
                        &:focus + .form__label {
                            top: -15px;
                            left: 0;
                            font-family: $f1-font-regular;
                            font-size: 12px;
                            line-height: 12px;
                        }
                        &:not(:placeholder-shown) + .form__label:not(:focus) {
                            top: -15px;
                            left: 0;
                            font-family: $f1-font-regular;
                            font-size: 12px;
                            line-height: 12px;
                        }
                        &.invalid {
                            border: 0.1rem solid $fds-color-red;
                        }
                    }
                    .form__label {
                        position: absolute;
                        left: 15px;
                        top: 50%;
                        pointer-events: none;
                        transform: translateY(-50%);
                        color: $fds-color-dk-gray;
                        font-family: $f1-font-regular;
                        transition: all 0.3s ease-out;
                        &.invalid {
                            color: $fds-color-red;
                        }
                    }
                }
                .finalize-memberid-text {
                    margin: 1rem 0 3rem;
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 28px;

                    @include mobile {
                        margin: 1rem 0 1rem;
                    }
                }
                .sign-middle-button {
                    margin-bottom: 2rem;
                }
                .find-member-id {
                    padding: 19px 0 0 0;
                    text-decoration: none;
                    .find-member-id-text {
                        color: $dark-blue;
                        font-family: $f1-font-regular;
                        height: 17px;
                        width: 169px;
                        font-size: 11px;
                        line-height: 21px;
                        &:hover {
                            cursor: pointer;
                            text-decoration: none;
                        }
                    }
                    .tooltip {
                        margin-left: -10px;
                        .tooltip__wrapper {
                            top: -20px;
                            left: 30px;
                        }
                        .tooltip__button {
                            cursor: pointer;
                            background-color: transparent;
                            border: 0px;
                            width: 160px;
                            margin-left: -9rem;
                            .tooltip__infoIcon {
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }
                }
            }
            .finalize-member-signin {
                flex: 1;
                .finalize-member-button {
                    display: flex;

                    @include tablet {
                        justify-content: flex-end;
                    }

                    @include desktop {
                        justify-content: flex-end;
                    }
                }
            }
        }
        .finalize-member-rebates-row > * {
            width: auto;
            width: 100%;
            box-sizing: border-box;

            @include mobile {
                padding: 1.5rem;
            }

            @include tablet {
                padding: 1.5rem 1rem;
            }

            @include desktop {
                padding: 0;
            }
        }

        fieldset {
            border: 0;
            padding: 0;
            margin: 0;
            min-width: 0;
        }

        .finalize-member-rebates-row-info {
            padding: 1rem 0 0 0;
            display: flex;
            flex-direction: column;

            @include tablet {
                flex-direction: row;
            }

            @include desktop {
                flex-direction: row;
            }
        }
        .finalize-member-rebates-row-info > * {
            flex: 1;
            width: 100%;
            padding: 1.5rem 0;
            box-sizing: border-box;

            @include mobile {
                padding: 0;
            }
        }

        .finalize-member-rebates-row-input {
            display: flex;
            flex-direction: column;

            @include tablet {
                flex-direction: row;
            }

            @include desktop {
                flex-direction: row;
            }
        }
        .finalize-member-rebates-row-input > * {
            flex: 1;
            width: 100%;
            padding: 1.5rem;
            box-sizing: border-box;
        }

        .finalize-member-contact-info {
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            padding: 1.5rem;

            @include tablet {
                height: 100px;
                padding: 0 1.5rem;
            }

            @include desktop {
                height: 60px;
                padding: 0 1.5rem;
            }
        }
        .finalize-row-elements {
            .form__div {
                position: relative;
                .form__input {
                    font-family: $f1-font-regular;
                    color: $fds-color-primary;
                    font-size: 16px;
                    line-height: 26px;
                    padding: 15px;
                    height: 50px;
                    width: 100%;
                    border-radius: 0.2rem;
                    border: 0.1rem solid $fds-color-md-gray;
                    transition: all 0.3s ease-in-out;
                    &:focus {
                        box-shadow: $outer-drop-shadow;
                        border: 1.5px solid $black;
                    }
                    &:focus + .form__label {
                        top: -15px;
                        left: 0;
                        font-family: $f1-font-regular;
                        font-size: 12px;
                        line-height: 12px;
                    }
                    &:not(:placeholder-shown) + .form__label:not(:focus) {
                        top: -15px;
                        left: 0;
                        font-family: $f1-font-regular;
                        font-size: 12px;
                        line-height: 12px;
                    }
                    &.invalid {
                        border: 0.1rem solid $fds-color-red;
                    }
                }
                .form__label {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    pointer-events: none;
                    transform: translateY(-50%);
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    transition: all 0.3s ease-out;
                    &.invalid {
                        color: $fds-color-red;
                    }
                }
            }
        }
        .finalize-member-rebates-checkbox {
            display: flex;
            align-items: flex-start;
            width: 100%;
            padding: 1.5rem;
            .finalize-checkbox {
                width: auto;
                height: auto;
                padding-right: 1rem;
                .checkbox-finalize {
                    transform: scale(1.4);
                }
            }

            .finalize-checkbox-label {
                font-size: 16px;
                line-height: 26px;
                font-family: $f1-font-regular;
                color: $fds-color-dk-gray;
            }
        }
        .finalize-checkbox-email {
            padding: 0 1.5rem;
            .checkbox-email {
                font-size: 12px;
                line-height: 18px;
                font-family: $f1-font-regular;
                color: $dark-blue;
            }
        }
        .finalize-response-button {
            display: flex;
            justify-content: center;
            margin-top: 2rem;

            @include tablet {
                justify-content: flex-end;
            }

            @include desktop {
                justify-content: flex-end;
                padding-right: 1.5rem;
            }

            .primary-button {
                &.disabled {
                    &::before {
                        box-shadow: none;
                        background-color: $fds-color-md-gray;
                    }
                    &:hover {
                        &::before {
                            background-color: $fds-color-md-gray !important;
                        }
                    }
                }
            }
        }

        .state-dropdown {
            border-radius: 0.2rem;
            border: 0.1rem solid $fds-color-md-gray;
            width: 100%;
            height: 50px;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            padding-left: 10px;
        }

        .form__error {
            font-family: $f1-font-regular;
            width: 100%;
            color: $error-text;
            margin: 1rem 0;
            font-size: 12px;
            line-height: 12px;
        }
    }
}
