.brand-ford {
    .redeem-rebates-online-info {
        max-width: 1440px;
        margin: 0 auto;
        .redeem-rebates {
            @include mobile {
                padding: 0 5%;
            }

            @include tablet {
                padding: 0 5%;
            }

            @include desktop {
                padding: 0 15%;
            }

            @media print {
                display: none;
            }

            .redeem-rebates-row {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
            }

            .redeem-rebates-container {
                flex: 1 300px;
                margin: 1rem;
                box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
                    0 10px 10px 0 rgba(0, 0, 0, 0.1);
                background-color: $white;
            }

            .redeem-content {
                text-align: center;
                padding: 5% 10% 15%;
            }

            .content-redeem {
                padding-top: 1rem;
                font-family: $f1-font-regular;
                color: $dropdown-text;
                font-size: 16px;
                line-height: 26px;
            }

            .center {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50px;
                height: 50px;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
            }

            .site-header {
                font-family: $f1-font-light;
                font-size: 20px;
                line-height: 30px;
                text-transform: uppercase;
                color: $dropdown-text;
                padding: 0 0 1rem 0;
            }
        }
    }
}
