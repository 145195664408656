.brand-ford {
    .autocomplete__wrapper {
        border-radius: pToR(30);
        font-family: $f1-font-light;
        border: none;
        box-sizing: border-box;
        color: $search-text;
        outline: none;
        font-size: pToR(16);
        transition: color 0.3s;
        width: 100%;
        height: 100%;
        line-height: pToR(26);
        position: relative;
        z-index: 10;
        &::placeholder {
            color: $search-text;
            opacity: 1;
            transition: color 0.3s;
        }

        &:focus-within {
            outline-width: 2px;
            outline-style: solid;
            outline-color: Highlight;

            @media (-webkit-min-device-pixel-ratio: 0) {
                outline-color: -webkit-focus-ring-color;
                outline-style: auto;
                outline-width: 5px;
            }

            .autocomplete__input {
                color: #333;

                &::placeholder {
                    color: #333;
                    opacity: 1;
                }
            }
        }
        .autocomplete__input:focus {
            background: $white;
        }
        .autocomplete__input:active {
            background: $white;
        }
        .autocomplete__input--default {
            background: $white;
        }
        .autocomplete__input {
            background: $white;
        }
    }

    .autocomplete__wrapper input {
        font-family: $f1-font-regular;
        font-size: 16px;
    }

    .autocomplete__input {
        height: 100%;
        position: relative;
        border: 0;
    }

    .autocomplete__hint,
    .autocomplete__input {
        -webkit-appearance: none;
        border-radius: pToR(
            30
        ); /* Safari 10 on iOS adds implicit border rounding. */
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin-bottom: 0; /* BUG: Safari 10 on macOS seems to add an implicit margin. */
        width: 100%;
        font-size: 1.5rem;
    }

    .autocomplete__hint {
        color: #b1b4b6;
        position: absolute;
    }

    .autocomplete__input--default {
        padding: 15px;
    }

    .autocomplete__input--show-all-values {
        padding: 5px 34px 5px 5px; /* Space for arrow. Other padding should match .autocomplete__input--default. */
        cursor: pointer;
    }

    .autocomplete__dropdown-arrow-down {
        z-index: -1;
        display: inline-block;
        position: absolute;
        right: 8px;
        width: 24px;
        height: 24px;
        top: 10px;
    }

    .autocomplete__menu {
        background-color: #fff;
        border: 0;
        border-top: 1px solid #ddd;
        color: #0276b3;
        margin: 0;
        max-height: 342px;
        overflow-x: hidden;
        padding: 0;
        width: 100%;
    }

    .autocomplete__menu--visible {
        display: block;
    }

    .autocomplete__menu--hidden {
        display: none;
    }

    .autocomplete__menu--overlay {
        box-shadow: rgba(0, 0, 0, 0.256863) 0px 2px 6px;
        left: 0;
        position: absolute;
        top: 100%;
        z-index: 100;
    }

    .autocomplete__menu--inline {
        position: relative;
    }

    .autocomplete__option {
        border-bottom: solid #b1b4b6;
        border-width: 1px 0;
        cursor: pointer;
        display: block;
        position: relative;
        font-size: 1rem;
    }

    .autocomplete__option > * {
        pointer-events: none;
    }

    .autocomplete__option:first-of-type {
        border-top-width: 0;
    }

    .autocomplete__option:last-of-type {
        border-bottom-width: 0;
    }

    .autocomplete__option--odd {
        background-color: #fafafa;
    }

    .autocomplete__option--focused,
    .autocomplete__option:hover {
        background-color: #1d70b8;
        border-color: #1d70b8;
        color: white;
        outline: none;
    }

    .autocomplete__option--no-results {
        background-color: #fafafa;
        color: #646b6f;
        cursor: not-allowed;
    }

    .autocomplete__hint,
    .autocomplete__input,
    .autocomplete__option {
        line-height: 1.25;
    }

    .autocomplete__hint,
    .autocomplete__option {
        padding: 5px;
    }

    @media (min-width: $mobile-width) {
        .autocomplete__hint,
        .autocomplete__input,
        .autocomplete__option {
            line-height: 1.31579;
        }
    }

    .fds-app {
        .autocomplete__wrapper {
            margin-left: 6.08rem;
            width: calc(100% - 6.08rem);
            z-index: 1;
        }

        .autocomplete__hint,
        .autocomplete__input {
            font-size: 2.4rem;
        }

        .autocomplete__option {
            font-size: 1.6rem;
            letter-spacing: normal;
        }
    }
}
