@import '../../../../../styles/lincoln/main.scss';

.brand-lincoln {
    .select-dealer-main {
        display: flex;
        flex-direction: column;
        padding: 40px 17px 0px;
        font-family: $proxima-nova-regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 24px;
        max-width: 1440px;
        margin: 0 auto;

        @include desktop {
            padding: 90px 205px 0px;
        }

        .select-dealer-search-result {
            display: flex;
            flex-direction: column;

            @include desktop {
                position: relative;
                flex-direction: row;
            }

            .search-result-text {
                color: $brand-secondary;
            }
        }

        .search-again-button {
            margin-top: 36px;
            margin-bottom: 45px;
            @include desktop {
                position: absolute;
                right: 10px;
                top: -7px;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .select-dealer-divider {
            box-shadow: none;
            @include desktop {
                margin-top: 50px;
            }
        }

        .no-dealers-found-divider {
            margin-bottom: 92.5px;
        }

        .select-dealer-filter {
            display: flex;
            margin-top: 24px;
            margin-bottom: 32px;
            color: $brand-secondary;
            label {
                font-family: $proxima-nova-regular;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 24px;
            }

            @include desktop {
                margin-top: 20px;
                margin-bottom: 38px;
            }
        }

        .select-dealer-checkbox {
            margin-top: 4px;
            margin-right: 12px;
            &:before {
                display: inline-block;
                color: $fds-color-primary;
                width: pToR(16);
                height: pToR(16);
                background-color: $fds-color-white;
                border: 2px solid $fds-color-dk-gray;
                border-radius: 3px;
                content: '';
                margin-right: 12px;
                box-sizing: border-box;
                text-align: center;
                padding: 0;
                font-weight: 800;
                font-size: 10px;
            }

            &:checked {
                &:before {
                    content: '\2713';
                    border-color: $fds-color-primary;
                }
            }
        }

        .dealer-name {
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 30px;
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: 17px;
            letter-spacing: 2px;
            line-height: 27px;
        }

        .dealer-address {
            color: $brand-secondary;
            margin-bottom: 43px;
            font-family: $proxima-nova-regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 24px;

            @include desktop {
                position: relative;
                margin-bottom: 50px;
            }

            .dealer-address-details {
                width: 70%;
            }

            .select-dealer-button {
                margin-top: 30px;
                @include desktop {
                    position: absolute;
                    right: 10px;
                    top: -12px;
                    margin-top: 0;
                }
            }
        }

        .dealer-divider {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .show-more-with-search-plus {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .secondary-button {
                .secondary-button-link {
                    .secondary-button-text {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        padding-bottom: 0;
                        border: none;
                        &:hover {
                            border-width: 0;
                            padding-bottom: 1px;
                            border-bottom: 2px solid $rebates-button-orange;
                        }
                    }
                }
            }

            .show-more-button {
                padding: 0;
            }

            .plus-circle {
                height: 24px;
                width: 24px;
                margin-right: 10px;
            }

            .page-count {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 17px;
                text-align: right;
            }
        }

        .show-more-dealer-divider {
            margin-top: 30px;
            margin-bottom: 127.5px;
        }
    }
}
