@import '../../../../../styles/lincoln/main.scss';

.brand-lincoln {
    .redeem-rebates-form {
        max-width: 1440px;
        margin: 0 auto;

        @include tablet {
            padding: 3rem 0;
        }
        .rebates_form_elements {
            max-width: 102.4rem;
            margin: 0;
            padding: 0;

            @include tablet {
                padding: 0 2rem;
            }
            .rebates-field-row {
                flex-direction: column;
                margin: 0;
                padding: 0;

                @include tablet {
                    margin: 0;
                    padding: 0;
                }

                @include desktop {
                    margin: auto auto 2rem;
                    flex-direction: row;
                    display: flex;
                    max-width: 102.4rem;
                }
                .field-row-elements {
                    .zipcode-search-tabs {
                        display: block;
                        margin: auto;
                        max-width: 50rem;
                        font-weight: 700;
                        font-size: 1.2rem;
                        color: $brand-secondary;
                        letter-spacing: 0.008rem;
                        margin-top: -0.4em;
                        height: 4rem;

                        @include desktop {
                            margin-top: -3.4em;
                        }
                        .search-tab {
                            color: $brand-secondary;
                            border: transparent;
                            background: $white;
                            font-family: $proxima-nova-regular;
                            font-size: 18px;
                            padding-right: 7px;
                            letter-spacing: 0;
                            line-height: 26px;
                            font-weight: lighter;
                        }
                        .active-dealer-search-tab {
                            cursor: pointer;
                            text-decoration: underline;
                            color: $brand-secondary;
                            border: transparent;
                            background: $white;
                            font-family: $proxima-nova-regular;
                            font-size: 18px;
                            letter-spacing: 0;
                            line-height: 26px;
                            font-weight: lighter;
                            &.active {
                                color: $brand-secondary;
                                text-decoration: none;
                            }
                        }
                    }
                    .rebates__form__div {
                        position: relative;
                        margin-top: 1rem;
                        height: 50px;
                        background-color: $white;
                        @include desktop {
                            width: 393px;
                        }
                        @media (min-width: 992px) and (max-width: 1300px) {
                            width: auto;
                        }
                        .dropdown-container-wrapper {
                            width: auto;
                            padding-top: 0px;
                        }
                        .react-datepicker__header {
                            background-color: $white;
                            border-bottom: none;
                        }
                        .react-datepicker__day--keyboard-selected {
                            background-color: inherit;
                            border: 1px solid $brand-border;
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 11px;
                            letter-spacing: 1px;
                            line-height: 21px;
                            text-align: center;
                            font-weight: normal;
                        }
                        .react-datepicker__day--selected {
                            background-color: inherit;
                            border: 1px solid $brand-border;
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 11px;
                            letter-spacing: 1px;
                            line-height: 21px;
                            text-align: center;
                            font-weight: normal;
                        }
                        .react-datepicker__current-month {
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 11px;
                            letter-spacing: 1px;
                            line-height: 21px;
                            font-weight: normal;
                            margin-bottom: 15px;
                        }
                        .react-datepicker__day-name {
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 11px;
                            font-weight: bold;
                            letter-spacing: 1px;
                            line-height: 21px;
                            text-align: center;
                        }
                        .rebates__form__input {
                            font-family: $proxima-nova-regular;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 24px;
                            padding: 25px 15px 5px;
                            width: 100%;
                            height: 50px;
                            border: none;
                            background-color: $brand-gray2;
                            border-bottom: 0.1rem solid $brand-secondary;
                            transition: all 0.3s ease-in-out;
                            &:focus {
                                box-shadow: $outer-drop-shadow;
                                border: none;
                                border-bottom: 0.1rem solid $brand-border;
                            }
                            &:focus + .rebates__form__label {
                                top: 10px;
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                                font-size: 13px;
                                letter-spacing: 0;
                                line-height: 13px;
                            }
                            &:not(:placeholder-shown)
                                + .rebates__form__label:not(:focus) {
                                top: 10px;
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                                font-size: 13px;
                                letter-spacing: 0;
                                line-height: 13px;
                            }
                        }
                        .rebates__citystate__form__input {
                            font-family: $proxima-nova-regular;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 24px;
                            padding: 25px 15px 5px;
                            width: 200%;
                            height: 50px;
                            border: none;
                            background-color: $brand-gray2;
                            border-bottom: 0.1rem solid $brand-secondary;
                            transition: all 0.3s ease-in-out;
                            @include tablet {
                                width: 100%;
                            }
                            @include desktop {
                                width: 200%;
                            }
                            &:focus {
                                box-shadow: $outer-drop-shadow;
                                border: none;
                                border-bottom: 0.1rem solid $brand-border;
                            }
                            &:focus + .rebates__form__label {
                                top: 10px;
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                                font-size: 13px;
                                letter-spacing: 0;
                                line-height: 13px;
                            }
                            &:not(:placeholder-shown)
                                + .rebates__form__label:not(:focus) {
                                top: 10px;
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                                font-size: 13px;
                                letter-spacing: 0;
                                line-height: 13px;
                            }
                        }
                        .react-datepicker-wrapper {
                            width: 100%;
                        }
                        .rebates__date__form__input {
                            font-family: $proxima-nova-regular;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 24px;
                            padding: 25px 15px 5px;
                            height: 50px;
                            border: none;
                            background-color: $brand-gray2;
                            border-bottom: 0.1rem solid $brand-secondary;
                            transition: all 0.3s ease-in-out;
                            width: 100%;

                            &:focus {
                                box-shadow: $outer-drop-shadow;
                                border: none;
                                border-bottom: 0.1rem solid $brand-border;
                            }
                            &:focus + .rebates__form__label {
                                top: 10px;
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                                font-size: 13px;
                                letter-spacing: 0;
                                line-height: 13px;
                            }
                            &:not(:placeholder-shown)
                                + .rebates__form__label:not(:focus) {
                                top: 10px;
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                                font-size: 13px;
                                letter-spacing: 0;
                                line-height: 13px;
                            }
                        }
                        .rebates__form__label {
                            position: absolute;
                            left: 15px;
                            top: 50%;
                            pointer-events: none;
                            transform: translateY(-50%);
                            color: $brand-secondary;
                            font-family: $proxima-nova-regular;
                            transition: all 0.3s ease-out;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 20px;
                            &.up {
                                top: 0.7rem;
                                color: $brand-secondary;
                                font-size: 14px;
                            }
                        }
                        .city_state_fields {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: column;
                            margin-bottom: 10px;

                            @include desktop {
                                flex-direction: row;
                            }
                            @include tablet {
                                flex-direction: row;
                            }
                            .dropdown-container-wrapper {
                                @include mobile {
                                    padding-top: 25px;
                                }
                            }
                            .city_input_field {
                                flex: 1;
                                padding: 1px;
                                margin-right: 1rem;
                                width: 50%;
                            }
                            .state-dropdown {
                                flex: 1;
                                width: 165%;
                                right: 5.9rem;

                                @include desktop {
                                    width: 50%;
                                    right: 0;
                                }
                            }
                            .dropdown-button {
                                width: 60%;
                                margin-left: 6rem;
                                height: 50px;
                                margin-top: 1.5px;
                                background-color: $brand-gray2;
                                border: transparent;
                                border-bottom: 0.1rem solid $brand-secondary;
                            }
                            .dropdown-items-panel {
                                color: inherit;
                                display: block;
                                position: absolute;
                                top: 48px;
                                left: 6rem;
                                z-index: 98;
                                min-width: 60%;
                                overflow-y: auto;
                                max-height: 300px;
                                box-sizing: border-box;
                                list-style: none;
                                margin: 0;
                                padding: 0;

                                .dropdown-item {
                                    display: block;
                                    box-sizing: border-box;
                                    width: 100%;
                                    margin: 0;
                                    line-height: 0.5px;
                                    padding: 16px;
                                    position: relative;
                                }
                            }
                        }
                    }
                    .error_warning {
                        font-family: $proxima-nova-regular;
                        width: 100%;
                        color: $brand-dark-error;
                        margin: 1rem 0;
                        letter-spacing: 1px;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
            }
            .field-row-elements {
                flex: 2;
            }
            .field-row-button {
                flex: 1;
                margin-top: 5rem;
                display: flex;
                justify-content: center;
                @include desktop {
                    margin-top: 3rem;
                }
            }

            .find-vin-clickbox {
                padding: 19px 0 0 0;
                text-decoration: none;
                .find-vin-text {
                    color: $brand-secondary;
                    border-bottom: 1px solid $brand-secondary;
                    font-family: $proxima-nova-regular;
                    letter-spacing: 1px;
                    height: 17px;
                    width: 169px;
                    font-size: 11px;
                    line-height: 21px;
                    &:hover {
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
                .tooltip {
                    margin-left: -10px;
                    .tooltip__wrapper {
                        background-color: $brand-secondary;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
                        color: $white;
                        font-family: $proxima-nova-regular;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 24px;
                        padding-top: 45px;
                        padding-right: 35px;
                        top: -20px;
                        left: 30px;
                        @media (max-width: 992px) {
                            margin-left: 8px;
                        }
                    }
                    .tooltip__button {
                        cursor: pointer;
                        border: 0px;
                        width: 160px;
                        margin-left: -9rem;
                        .tooltip__infoIcon {
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    .tooltip__pointer-fill__resize {
                        border-color: transparent transparent $brand-secondary
                            transparent;
                    }
                    .tooltip__pointer-fill {
                        border-color: transparent $brand-secondary transparent
                            transparent;
                        @media (max-width: 992px) {
                            border-color: transparent transparent transparent
                                $brand-secondary;
                        }
                    }
                }
            }
            .rebates-link-button {
                @include tablet {
                    margin-top: 60px;
                }
                @include mobile {
                    margin-top: 80px;
                }
                .field-row-button {
                    padding-left: 1em;
                    padding-bottom: 3em;
                    @include desktop {
                        margin-top: -2rem;
                        padding-left: 69em;
                    }
                    @media (min-width: 992px) and (max-width: 1300px) {
                        padding-left: 85%;
                    }
                }
                .signin-text {
                    display: block;
                    color: $brand-secondary;
                    height: 26px;
                    font-family: $proxima-nova-regular;
                    font-size: 16px;
                    letter-spacing: 1px;
                    line-height: 26px;
                    margin-left: 1.3rem;
                    .anchor-link {
                        color: $brand-secondary;
                        border-bottom: $brand-secondary;
                        padding-right: 6px;
                    }
                }
            }
            .rebates-field-row > * {
                width: auto;
                width: 100%;
                box-sizing: border-box;
                padding: 1.5rem;
                margin-top: 1rem;

                @include tablet {
                    padding: 1.5rem 1rem;
                }
            }
        }
    }
}
