.brand-lincoln {
    .search-container {
        font-family: $proxima-nova-regular;
        align-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 50px 18px;
        @include tablet {
            display: inline-block;
            margin: 0 auto;
            padding: 100px 0;
            max-width: 332px;
        }
        @include md-desktop {
            max-width: 700px;
        }
        input[type='search']::-webkit-search-cancel-button {
            display: none;
        }
        input:focus::placeholder {
            color: lighten($fds-color-dk-gray, 50%);
        }
        .search-container-hide-input {
            padding-top: 200px;

            @include mobile {
                padding-top: 400px;
            }
        }
        .autocomplete__menu {
            color: $brand-dark-grey;
        }
        .autocomplete__option:hover,
        .autocomplete__option--focused {
            background-color: $brand-border;
        }

        .search-title {
            @include text(22, 2.29px, 32px, $white, $proxima-nova-regular);
            text-transform: uppercase;
            text-align: center;
            margin: 0 0 28px 0;
        }

        .placeholder-text-offset {
            visibility: hidden;
            margin-right: pToR(60);
        }

        form {
            align-items: center;
            background-color: $white;
            border: 1px solid $ghost-grey;
            box-sizing: border-box;
            display: flex;
            text-align: center;
            width: 100%;
            height: pToR(60);
            .autocomplete__input {
                letter-spacing: 0px;
            }

            .search-icon {
                background: none;
                border: none;
                cursor: pointer;
                outline: none;
                position: absolute;
                padding: pToR(3) pToR(16) 0 pToR(20);

                use {
                    transition: 0.3s;
                }
            }

            .search-input {
                border: none;
                box-sizing: border-box;
                color: $brand-mild-gray2;
                font-family: $proxima-nova-regular;
                outline: none;
                padding: pToR(12) pToR(16) pToR(12) 0;
                transition: color 0.3s;
                width: 100%;
                height: 100%;
                line-height: pToR(20);
                letter-spacing: 0;

                &::placeholder {
                    color: $ghost-grey;
                    opacity: 1;
                    transition: color 0.3s;
                }
            }

            :focus {
                outline-width: 2px;
                outline-style: solid;
                outline-color: $highlight-blue;

                @media (-webkit-min-device-pixel-ratio: 0) {
                    outline-color: -webkit-focus-ring-color;
                    outline-style: auto;
                    outline-width: 5px;
                }

                .search-icon {
                    use {
                        fill: $black;
                    }
                }

                .search-input {
                    color: $black;

                    &::placeholder {
                        color: $black;
                    }
                }
            }
        }
    }
    .search-spinner-container {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        padding-top: 40vh;
        z-index: 1000;
    }
}
