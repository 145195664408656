@import '../../../../../styles/lincoln/main.scss';

.brand-lincoln {
    .rebates-container {
        @media print {
            display: none;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style-type: none;
        padding: 1rem 0 2rem 0;
        .rebates-elements {
            .nav-link {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 20px;
                cursor: pointer;
                border: none;
                display: inline-block;
                text-transform: capitalize;
                padding: 1rem;
                border-bottom: 1.12px solid $brand-gray3;
                &.active {
                    border-bottom: 2px solid $brand-border;
                }
            }
        }
    }
}
