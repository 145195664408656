@import '../../../../../styles/lincoln/main.scss';

.brand-lincoln {
    .redeem-rebates-top-text {
        max-width: 1440px;
        margin: 0 auto;
        padding-top: 1rem;
        width: 100%;
        font-size: 48px;
        font-weight: 300;
        letter-spacing: 3px;
        line-height: 58px;
        @include desktop {
            width: 100%;
        }

        .redeem-rebates-title {
            line-height: 48px;
            text-align: center;
            width: 100%;
            color: $brand-white;
            padding: 92px 2rem 0;
            font-family: $lincoln-millerb-roman;
            font-size: 40px;
            font-weight: 900;
            letter-spacing: 0;
        }

        .banner-description {
            line-height: 26px;
            width: 100%;
            font-family: $proxima-nova-light;
            font-weight: 300;
            text-align: center;
            padding: 0 1rem;
            color: $brand-white;
            font-size: 18px;
            letter-spacing: 0;
            @include tablet {
                padding: 1rem 18%;
            }
        }

        .secondary-button {
            .secondary-button-link {
                color: $brand-white;
                font-family: $proxima-nova-semi-bold;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 20px;

                @include desktop {
                    padding: 19px;
                }

                &:focus {
                    outline: 1px solid $brand-white;
                }
                &:hover {
                    color: $brand-white;
                    cursor: pointer;
                    .secondary-button-text {
                        text-decoration: underline;
                    }
                }

                .secondary-button-text {
                    border-bottom: none;
                }
                .fds-chevron-wrap {
                    display: block;
                    .fds-chevron {
                        .fds-dot {
                            display: none;
                        }
                        .fds-arrow {
                            color: $brand-white;
                        }
                    }
                }
            }
        }
    }
}
