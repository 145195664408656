@import '../../../../styles/lincoln/main.scss';

.brand-lincoln {
    .rebates-step1-page-form {
        max-width: 1440px;
        margin: 0 auto;
        display: block;
        padding: 64px 0 0 0;
        width: 100%;
        position: relative;
        @media print {
            display: none;
        }
        .rebates-page-toptext {
            text-align: center;
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            margin: 0 1rem;
            justify-content: center;
            .rebates-page-title {
                margin: 0 0 1.1rem 0;
                text-transform: uppercase;
                font-weight: 200;
                font-size: 24px;
                letter-spacing: 2.5px;
                line-height: 32px;
            }
            .rebates-form-text {
                font-size: 18px;
                letter-spacing: 0;
                line-height: 26px;

                @include desktop {
                    padding: 0 12rem;
                }
            }
        }
    }
}
