.brand-ford {
    .check-rebates-form {
        background-color: $white;
        padding: 3rem 0 0 0;
        .check-rebate-status {
            text-align: center;
            color: $fds-color-dk-gray;
            margin: 0 1rem;
            .form__title {
                margin: 0 0 1.1rem 0;
                text-transform: uppercase;
                font-size: 24px;
                line-height: 34px;
                font-family: $f1-font-light;
            }
            .form-text {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                .form-link {
                    color: $fds-color-dk-gray;
                }
            }
        }
        .l-form {
            max-width: 1440px;
            margin: 0 auto;
            @include desktop {
                padding: 3rem 5rem;
            }

            @include tablet {
                padding: 3rem 0;
            }

            @include mobile {
                padding: 3rem 0;
            }

            .form_elements {
                margin: auto auto 2rem;
                max-width: 102.4rem;

                @include mobile {
                    margin: 0;
                    padding: 0;
                }

                @include tablet {
                    padding: 0 2rem;
                }

                @include desktop {
                    padding: 0 5rem;
                }

                .service-rebates-row {
                    flex-direction: row;
                    display: flex;
                    max-width: 102.4rem;

                    @include mobile {
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                    }

                    @include tablet {
                        margin: 0;
                        padding: 0;
                    }

                    @include desktop {
                        margin: auto auto 2rem;
                        padding: 0 2rem;
                    }

                    .row-elements {
                        flex: 2;
                        .form__div {
                            position: relative;
                            .form__input {
                                font-family: $f1-font-regular;
                                font-size: 16px;
                                line-height: 26px;
                                padding: 15px;
                                width: 100%;
                                height: 50px;
                                border-radius: 0.2rem;
                                border: 0.1rem solid $fds-color-md-gray;
                                transition: all 0.3s ease-in-out;
                                &:focus {
                                    box-shadow: $outer-drop-shadow;
                                    border: 1.5px solid $black;
                                }
                                &:focus + .form__label {
                                    top: -15px;
                                    left: 0;
                                    font-family: $f1-font-regular;
                                    font-size: 12px;
                                    line-height: 12px;
                                }
                                &:not(:placeholder-shown)
                                    + .form__label:not(:focus) {
                                    top: -15px;
                                    left: 0;
                                    font-family: $f1-font-regular;
                                    font-size: 12px;
                                    line-height: 12px;
                                }
                                &.invalid {
                                    border: 0.1rem solid $fds-color-red;
                                }
                            }
                            .form__label {
                                position: absolute;
                                left: 15px;
                                top: 50%;
                                pointer-events: none;
                                transform: translateY(-50%);
                                color: $fds-color-dk-gray;
                                font-family: $f1-font-regular;
                                transition: all 0.3s ease-out;
                                &.invalid {
                                    color: $fds-color-red;
                                }
                            }
                        }
                        .form__error {
                            font-family: $f1-font-regular;
                            width: 100%;
                            color: $error-text;
                            margin: 1rem 0;
                            font-size: 12px;
                            line-height: 12px;
                        }

                        .form__warning {
                            text-align: center;
                            text-transform: capitalize;
                        }

                        .secondary-button {
                            padding-top: 30px;
                            .secondary-button-link {
                                color: $dark-blue;
                                padding: 19px 0;
                                .secondary-button-text {
                                    text-align: left;
                                    text-decoration: underline;
                                    font-family: $f1-font-regular;
                                    font-size: 16px;
                                    line-height: 26px;
                                }
                                &:hover {
                                    color: $light-blue;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .row-button {
                        flex: 1;
                        @include mobile {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
                .service-rebates-row > * {
                    width: auto;
                    width: 100%;
                    box-sizing: border-box;

                    @include mobile {
                        padding: 1.5rem;
                    }

                    @include tablet {
                        padding: 1.5rem 1rem;
                    }

                    @include desktop {
                        padding: 1.5rem;
                    }
                }
                .form__error {
                    font-family: $f1-font-regular;
                    width: 100%;
                    color: $error-text;
                    margin: 1rem 0;
                    font-size: 12px;
                    line-height: 12px;
                }

                .form__warning {
                    text-align: center;
                    text-transform: capitalize;
                }
            }
        }
    }

    .whole-rebate-status-response {
        max-width: 1440px;
        margin: 0 auto;
        .rebate-status-response {
            padding: 2rem;
            max-width: 102.4rem;
            background-color: $white;

            @include mobile {
                margin: auto;
            }

            @include tablet {
                margin: auto;
            }

            @include desktop {
                margin: auto 10rem;
            }

            .header-text {
                text-transform: uppercase;
                font-family: $f1-font-light;
                color: $fds-color-dk-gray;
                font-size: 24px;
                line-height: 34px;
                text-align: center;
                margin: 2rem 0;
            }
            .rewards-point {
                font-size: 18px;
                line-height: 26px;
                color: $dark-blue;
                font-family: $f1-font-regular;
            }
            .rebates-row {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
            .rebate-status {
                font-family: $f1-font-regular;
                color: $dark-blue;
                font-size: 16px;
                line-height: 26px;
                text-transform: uppercase;
            }
            .rebate-response {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                color: $fds-color-dk-gray;
            }
            .rebatesRes-elements {
                padding: 2rem 0;
            }

            hr {
                box-sizing: border-box;
                border: 1px solid $fds-color-border-md-gray;
                box-shadow: none;
            }

            .rebates-content {
                padding: 2rem 0;
            }

            .rebates-elementals {
                padding: 0 3rem 0 0;
            }

            .rebate-response-button {
                display: flex;
                justify-content: flex-end;

                @include mobile {
                    justify-content: center;
                }
            }
        }

        .rebates-print {
            text-align: right;
            .print-button {
                background-color: $white;
                border: none;
                box-shadow: none;
                &:hover {
                    transform: scale(1.07);
                    cursor: pointer;
                }
            }
        }

        @media print {
            display: none;
        }
    }

    .rebates-offer-print {
        display: none;
        @media print {
            display: initial;
        }

        .rebates-print {
            display: none;
        }

        .rewards-point {
            text-align: center;
        }

        .rebates-row {
            display: flex;
            justify-content: center;
        }

        .rebates-elementals {
            padding: 2rem 2rem 0 0;
            .rebate-status {
                font-family: $f1-font-regular;
                color: $dark-blue;
                font-size: 16px;
                line-height: 26px;
                text-transform: uppercase;
            }
            .rebate-response {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                color: $fds-color-dk-gray;
            }
        }
    }
}
