.brand-ford {
    .redeem-rebates-form {
        max-width: 1440px;
        margin: 0 auto;
        padding: 3rem 0;
        .rebates_form_elements {
            max-width: 102.4rem;
            margin: 0;
            padding: 0;

            @include tablet {
                padding: 0 2rem;
            }
            .rebates-field-row {
                flex-direction: column;
                margin: 0;
                padding: 0;

                @include tablet {
                    margin: 0;
                    padding: 0;
                }

                @include desktop {
                    margin: auto auto 2rem;
                    flex-direction: row;
                    display: flex;
                    max-width: 102.4rem;
                }
                .field-row-elements {
                    .zipcode-search-tabs {
                        display: block;
                        margin: auto;
                        max-width: 50rem;
                        font-size: 1.2rem;
                        color: $rebates-tab-grey;
                        margin-top: -0.4em;
                        height: 4rem;

                        @include desktop {
                            margin-top: -3.4em;
                        }
                        .search-tab {
                            color: $fds-color-md-gray;
                            border: transparent;
                            background: $white;
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            padding-right: 7px;
                            line-height: 26px;
                        }
                        .active-dealer-search-tab {
                            cursor: pointer;
                            text-decoration: underline;
                            color: $dark-blue;
                            border: transparent;
                            background: $white;
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            line-height: 26px;
                            &.active {
                                color: $fds-color-md-gray;
                            }
                        }
                    }
                    .rebates__form__div {
                        position: relative;
                        margin-top: 1rem;
                        height: 50px;
                        background-color: $white;
                        @include desktop {
                            width: 393px;
                        }
                        @media (min-width: 992px) and (max-width: 1300px) {
                            width: auto;
                        }
                        .dropdown-container-wrapper {
                            width: auto;
                            padding-top: 0px;
                        }
                        .react-datepicker__header {
                            background-color: $white;
                            border-bottom: none;
                        }
                        .react-datepicker__day--keyboard-selected {
                            background-color: inherit;
                            border: 1px solid $fds-color-dk-gray;
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 11px;
                            line-height: 21px;
                            text-align: center;
                            font-weight: normal;
                        }
                        .react-datepicker__day--selected {
                            background-color: inherit;
                            border: 1px solid $fds-color-dk-gray;
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 11px;
                            line-height: 21px;
                            text-align: center;
                            font-weight: normal;
                        }
                        .react-datepicker__current-month {
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 11px;
                            line-height: 21px;
                            font-weight: normal;
                            margin-bottom: 15px;
                        }
                        .react-datepicker__day-name {
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            font-size: 11px;
                            line-height: 21px;
                            text-align: center;
                        }
                        .rebates__form__input {
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            line-height: 26px;
                            padding: 15px;
                            width: 100%;
                            height: 50px;
                            border-radius: 0.2rem;
                            border: 0.1rem solid $fds-color-border-gray;
                            transition: all 0.3s ease-in-out;
                            &:focus {
                                box-shadow: $outer-drop-shadow;
                                border: 1.5px solid black;
                            }
                            &:focus + .rebates__form__label {
                                top: -15px;
                                left: 0;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                line-height: 12px;
                            }
                            &:not(:placeholder-shown)
                                + .rebates__form__label:not(:focus) {
                                top: -15px;
                                left: 0;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                line-height: 12px;
                            }
                        }
                        .rebates__citystate__form__input {
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            line-height: 26px;
                            width: 200%;
                            height: 50px;
                            padding: 15px;
                            border-radius: 0.2rem;
                            border: 0.1rem solid $fds-color-border-gray;
                            transition: all 0.3s ease-in-out;
                            @include tablet {
                                width: 100%;
                            }
                            @include desktop {
                                width: 200%;
                            }
                            &:focus {
                                box-shadow: $outer-drop-shadow;
                                border: 1.5px solid $black;
                            }
                            &:focus + .rebates__form__label {
                                top: -15px;
                                left: 0;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                line-height: 12px;
                            }
                            &:not(:placeholder-shown)
                                + .rebates__form__label:not(:focus) {
                                top: -15px;
                                left: 0;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                line-height: 12px;
                            }
                        }
                        .react-datepicker-wrapper {
                            width: 100%;
                        }
                        .rebates__date__form__input {
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            line-height: 26px;
                            padding: 15px;
                            height: 50px;
                            border-radius: 0.2rem;
                            border: 0.1rem solid $fds-color-border-gray;
                            transition: all 0.3s ease-in-out;
                            width: 100%;

                            &:focus {
                                box-shadow: $outer-drop-shadow;
                                border: 1.5px solid $black;
                            }
                            &:focus + .rebates__form__label {
                                top: -15px;
                                left: 0;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                line-height: 12px;
                            }
                            &:not(:placeholder-shown)
                                + .rebates__form__label:not(:focus) {
                                top: -15px;
                                left: 0;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                line-height: 12px;
                            }
                        }
                        .rebates__form__label {
                            position: absolute;
                            left: 15px;
                            top: 50%;
                            pointer-events: none;
                            transform: translateY(-50%);
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-regular;
                            transition: all 0.3s ease-out;
                            &.up {
                                top: -1rem;
                                left: 0;
                                color: $help-text;
                                font-size: 14px;
                            }
                        }
                        .city_state_fields {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: column;
                            margin-bottom: 10px;

                            @include desktop {
                                flex-direction: row;
                            }
                            @include tablet {
                                flex-direction: row;
                            }
                            .dropdown-container-wrapper {
                                @include mobile {
                                    padding-top: 25px;
                                }
                            }
                            .city_input_field {
                                flex: 1;
                                padding: 1px;
                                margin-right: 1rem;
                                width: 50%;
                            }
                            .state-dropdown {
                                flex: 1;
                                width: 165%;
                                right: 5.9rem;

                                @include desktop {
                                    width: 50%;
                                    right: 0;
                                }
                            }
                            .dropdown-button {
                                width: 60%;
                                margin-left: 6rem;
                                height: 50px;
                                margin-top: 1.5px;
                                background-color: $white;
                            }
                            .dropdown-items-panel {
                                color: inherit;
                                display: block;
                                position: absolute;
                                top: 48px;
                                left: 6rem;
                                z-index: 98;
                                background-color: $gray;
                                min-width: 60%;
                                border: 0.96px solid $border-grey;
                                overflow-y: auto;
                                max-height: 300px;
                                box-sizing: border-box;
                                list-style: none;
                                margin: 0;
                                padding: 0;

                                .dropdown-item {
                                    display: block;
                                    box-sizing: border-box;
                                    width: 100%;
                                    margin: 0;
                                    line-height: 0.5px;
                                    padding: 16px;
                                    position: relative;
                                }
                            }
                        }

                        .dropdown-button {
                            background-color: $white;
                        }
                    }
                    .error_warning {
                        font-family: $f1-font-regular;
                        width: 100%;
                        color: $error-text;
                        margin: 1rem 0;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
            }
            .field-row-elements {
                flex: 2;
            }
            .field-row-button {
                flex: 1;
                margin-top: 5rem;
                display: flex;
                justify-content: center;
                @include desktop {
                    margin-top: 3rem;
                }
            }

            .find-vin-clickbox {
                padding: 19px 0 0 0;
                text-decoration: none;
                float: left;
                .find-vin-text {
                    color: $dark-blue;
                    border-bottom: 1px solid $dark-blue;
                    font-family: $f1-font-regular;
                    height: 17px;
                    width: 169px;
                    font-size: 11px;
                    line-height: 21px;
                    &:hover {
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
                .tooltip {
                    margin-left: -10px;
                    .tooltip__wrapper {
                        top: -20px;
                        left: 30px;
                    }
                    .tooltip__button {
                        cursor: pointer;
                        background-color: transparent;
                        border: 0px;
                        width: 160px;
                        margin-left: -9rem;
                        .tooltip__infoIcon {
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
            }

            .rebates-link-button {
                @include tablet {
                    margin-top: 60px;
                }
                @include mobile {
                    margin-top: 80px;
                }
                .field-row-button {
                    padding-left: 1em;
                    padding-bottom: 3em;
                    @include desktop {
                        margin-top: -2rem;
                        padding-left: 69em;
                    }
                    @media (min-width: 992px) and (max-width: 1300px) {
                        padding-left: 85%;
                    }
                    .primary-button {
                        border-radius: 2rem;
                        height: 40px;
                        width: 158px;
                        border-bottom: transparent;
                        right: 0;
                        &.disabled {
                            &::before {
                                box-shadow: none;
                                background-color: $fds-color-md-gray;
                            }
                            &:hover {
                                &::before {
                                    background-color: $fds-color-md-gray !important;
                                }
                            }
                        }
                    }
                }
                .signin-text {
                    display: block;
                    color: $dark-blue;
                    height: 26px;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 26px;
                    margin-left: 1.3rem;
                    .anchor-link {
                        color: $dark-blue;
                        border-bottom: $dark-blue;
                        padding-right: 6px;
                    }
                }
            }
            .rebates-field-row > * {
                width: auto;
                width: 100%;
                box-sizing: border-box;
                padding: 1.5rem;
                margin-top: 1rem;

                @include tablet {
                    padding: 1.5rem 1rem;
                }
            }
        }
    }
}
