.brand-ford {
    .search-container {
        font-family: $f1-font-light;
        align-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 112px 0 100px;
        input[type='search']::-webkit-search-cancel-button {
            display: none;
        }
        input:focus::placeholder {
            color: lighten($fds-color-dk-gray, 50%);
        }
        .search-container-hide-input {
            padding-top: 200px;

            @include mobile {
                padding-top: 400px;
            }
        }

        .search-title {
            font-size: pToR(48);
            font-weight: normal;
            text-transform: uppercase;
            line-height: pToR(58);
            margin-bottom: pToR(35);
        }

        .placeholder-text-offset {
            visibility: hidden;
            margin-right: pToR(60);
        }

        form {
            align-items: center;
            background-color: $white;
            border: pToR(1) solid $border-grey;
            border-radius: pToR(30);
            box-sizing: border-box;
            display: flex;
            text-align: center;
            width: 90%;
            height: pToR(50);

            @include tablet {
                width: pToR(675);
            }

            .search-icon {
                background: none;
                border: none;
                cursor: pointer;
                outline: none;
                position: absolute;
                padding: pToR(3) pToR(16) 0 pToR(20);

                use {
                    transition: 0.3s;
                }
            }

            .search-input {
                border-radius: pToR(30);
                font-family: $f1-font-light;
                border: none;
                box-sizing: border-box;
                color: $search-text;
                outline: none;
                font-size: pToR(16);
                padding: pToR(12) pToR(16) pToR(12) 0;
                transition: color 0.3s;
                width: 100%;
                height: 100%;
                line-height: pToR(26);

                &::placeholder {
                    color: $search-text;
                    opacity: 1;
                    transition: color 0.3s;
                }
            }

            :focus {
                outline-width: 2px;
                outline-style: solid;
                outline-color: $highlight-blue;

                @media (-webkit-min-device-pixel-ratio: 0) {
                    outline-color: -webkit-focus-ring-color;
                    outline-style: auto;
                    outline-width: 5px;
                }

                .search-icon {
                    use {
                        fill: $black;
                    }
                }

                .search-input {
                    color: $black;

                    &::placeholder {
                        color: $black;
                    }
                }
            }
        }
    }
    .search-spinner-container {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        padding-top: 40vh;
        z-index: 1000;
    }
}
