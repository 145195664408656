.brand-ford {
    .preimagine-search-bar {
        margin-bottom: 48px;
        .search-container {
            font-family: $f1-font-light;
            display: flex;
            flex-direction: column;
            align-items: start;
            width: 100%;
            input[type='search']::-webkit-search-cancel-button {
                display: none;
            }
            padding: 0;

            .search-title {
                margin-bottom: unset;
                font-size: 40px;
                font-style: normal;
                line-height: 56px;
                font-family: $f1-font-regular;
                color: $f1-text-blue;
                text-transform: none;
                @include desktop {
                    font-size: 54px;
                    line-height: 64px;
                }
            }

            .search-helper-text {
                font-size: 16px;
                line-height: 24px;
                font-family: $f1-font-regular;
                color: $f1-text-blue;
                margin-top: 16px;
            }

            form {
                align-items: center;
                align-content: center;
                background-color: $white;
                border: 1px solid $border-grey;
                border-radius: 8px;
                box-sizing: border-box;
                display: flex;
                text-align: center;
                width: 480px;
                height: 40px;
                padding: 8px;
                margin-top: 32px;
                @include tablet {
                    width: 480px;
                }
                @media (max-width: 991px) {
                    width: 100%;
                }

                .search-icons {
                    use {
                        transition: 0.3s;
                    }
                }

                .search-input {
                    font-family: $f1-font-light;
                    box-sizing: border-box;
                    color: $search-text;
                    outline: none;
                    font-size: pToR(16);
                    padding: pToR(12) pToR(16) pToR(12) 0;
                    transition: color 0.3s;
                    width: 100%;
                    height: 100%;
                    line-height: pToR(26);

                    &::placeholder {
                        color: $search-text;
                        opacity: 1;
                        transition: color 0.3s;
                    }
                }
                .autocomplete__wrapper {
                    align-items: center;
                    outline: none;
                    .autocomplete__input--default {
                        padding: 0 12px;
                        background: $white;
                    }
                    .autocomplete__input {
                        background: $white;
                    }
                    .autocomplete__option {
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                    }
                    .autocomplete__menu {
                        border: none;
                        outline: none;
                        margin-top: 8px;
                        &:last-child {
                            border-bottom: 1px solid black;
                        }
                    }
                    .autocomplete__input:focus {
                        outline: none;
                        background: $white;
                    }
                }
                :focus {
                    outline-width: 2px;
                    outline-style: solid;
                    outline-color: $highlight-blue;

                    @media (-webkit-min-device-pixel-ratio: 0) {
                        outline-color: -webkit-focus-ring-color;
                        outline-style: auto;
                        outline-width: 5px;
                    }

                    .search-input {
                        color: $black;

                        &::placeholder {
                            color: $black;
                        }
                    }
                }
            }
        }
        .search-spinner-container {
            position: fixed;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            padding-top: 40vh;
            z-index: 1000;
        }
    }
}
