.brand-ford {
    .redeem-rebates-top-text {
        max-width: 1440px;
        margin: 0 auto;
        padding-top: 1rem;
        width: 100%;
        font-family: $f1-font-regular;
        font-size: 48px;
        line-height: 58px;
        @include desktop {
            width: 100%;
        }

        .redeem-rebates-title {
            font-family: $f1-font-light;
            font-size: 48px;
            line-height: 58px;
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            color: $fds-color-white;
            padding: 92px 2rem 0;

            @include tablet {
                padding: 51px 25% 0 25%;
            }

            @include desktop {
                padding: 51px 25% 0 25%;
            }
        }

        .banner-description {
            font-size: 16px;
            line-height: 26px;
            width: 100%;
            font-family: $f1-font-light;
            text-align: center;
            padding: 0 1rem;
            color: $fds-color-white;
            @include desktop {
                width: 100%;
                padding: 1rem 15%;
            }
        }

        .redeem-rebates-description {
            height: 104px;
            width: 100%;
            color: $fds-color-white;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            margin-top: 24px;

            @include desktop {
                text-align: center;
                height: 52px;
                width: 100%;
                font-family: $f1-font-regular;
            }
        }
        .secondary-button {
            .secondary-button-link {
                @include mobile {
                    padding: 0;
                }

                @include desktop {
                    padding: 19px;
                }

                color: $white;
                &:focus {
                    outline: 1px solid $white;
                }
                &:hover {
                    color: $white;
                    cursor: pointer;
                    .secondary-button-text {
                        text-decoration: underline;
                    }
                }
                .fds-arrow {
                    &.filled {
                        color: $black;
                    }
                }
            }
        }
    }
}
