.brand-ford {
    .rebates-step1-page-form {
        max-width: 1440px;
        margin: 0 auto;
        display: block;
        padding: 64px 0 0 0;
        width: 100%;
        position: relative;
        @media print {
            display: none;
        }
        .rebates-page-toptext {
            text-align: center;
            color: $fds-color-dk-gray;
            margin: 0 1rem;
            justify-content: center;
            .rebates-page-title {
                margin: 0 0 1.1rem 0;
                text-transform: uppercase;
                font-size: 24px;
                line-height: 34px;
                font-family: $f1-font-regular;
            }
            .rebates-form-text {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                text-align: center;

                @include desktop {
                    padding: 0 12rem;
                }
            }
        }
    }
}
