@import '../../../../styles/main.scss';

.redeem-rebates-banner {
    .redeem-rebates-top {
        background-position: center;
        background-size: cover;
        color: $white;
        font-family: $f1-font-regular;
        height: 658px;
        width: 100%;
        margin: auto;

        @include tablet {
            height: 400px;
        }

        @include desktop {
            height: 400px;
            width: 100%;
            margin: 0;
        }
        &.no-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .redeem-rebates-tabs {
            font-family: $f1-font-light;
            text-align: center;
            font-size: 16px;
            padding: 2rem 0 0 0;
            line-height: 26px;
            @include desktop {
                padding: 2rem 0.5rem 0 0.5rem;
            }
        }
        .redeem-rebates-cta {
            text-align: center;
            margin: 0;
            @include desktop {
                max-width: 100%;
                margin: 0 pToR(18);
            }
        }
    }

    .redeem-rebates-bottom-container {
        position: relative;
        width: 100%;
        margin: auto;
        @include desktop {
            width: 100%;
            height: 64px;
        }
        .redeem-rebates-bottom {
            padding-top: 1rem;
            position: relative;
            display: flex;
            justify-content: center;
        }
    }
    .redeem-rebates-bottom-but-one-container {
        position: relative;
        width: 100%;
        margin: auto;
        @include desktop {
            width: 100%;
            height: 64px;
        }
        .redeem-rebates-bottom-but-one {
            padding: 1rem 0 0;
            text-align: center;
        }
    }
}
