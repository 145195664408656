.brand-lincoln {
    .redeem-rebates-tracker-inclusion {
        .fmc-tracker {
            --dot-size-standard: 3.8rem;
            margin-top: 2.1rem;
            @include mobile {
                overflow-x: scroll;
                justify-content: flex-start;
                padding-bottom: 10px;
            }
            .fmc-tracker__dot {
                font-size: 12px;
            }
            .fmc-tracker__title {
                color: $fds-color-dk-gray;
                font-family: $f1-font-light;
                font-size: 16px;
                letter-spacing: 1px;
                line-height: 26px;
                text-align: center;
                margin-top: 0;
                padding: 0;
            }
            .fmc-tracker__step::before {
                height: 1px;
            }
            .fds-icon {
                font-size: 1rem;
            }
        }
    }
}
