.brand-ford {
    .confirm-rebates-offer-print {
        display: none;
        @media print {
            display: initial;
        }

        .confirm-rebates-print {
            display: none;
        }

        .confirm-rewards-point {
            text-align: center;
        }

        .confirm-rebates-row {
            display: flex;
            justify-content: center;
        }

        .confirm-rebates-elementals {
            padding: 2rem 2rem 0 0;
            .confirm-rebate-status {
                font-family: $f1-font-regular;
                color: $dark-blue;
                font-size: 16px;
                line-height: 26px;
                text-transform: uppercase;
            }
            .confirm-rebate-response {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                color: $fds-color-dk-gray;
            }
        }
    }
    .confirm-rebate-status-response {
        padding: 2rem;
        max-width: 102.4rem;
        background-color: $white;

        @include mobile {
            margin: auto;
        }

        @include tablet {
            margin: auto;
        }

        @include desktop {
            margin: auto 10rem;
        }

        .confirm-header-text {
            text-transform: uppercase;
            font-family: $f1-font-light;
            color: $fds-color-dk-gray;
            font-size: 24px;
            line-height: 34px;
            text-align: center;
            margin: 2rem 0;
        }
        .confirm-rewards-point {
            font-size: 18px;
            line-height: 26px;
            color: $dark-blue;
            font-family: $f1-font-regular;
        }
        .confirm-rebates-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .confirm-rebate-status {
            font-family: $f1-font-regular;
            color: $dark-blue;
            font-size: 16px;
            line-height: 26px;
            text-transform: uppercase;
        }
        .confirm-rebate-response {
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            color: $fds-color-dk-gray;
        }
        .confirm-rebatesRes-elements {
            padding: 2rem 0;
        }

        hr {
            box-sizing: border-box;
            border: 1px solid $fds-color-border-md-gray;
            box-shadow: none;
        }

        .confirm-rebates-content {
            padding: 2rem 0;
        }

        .confirm-rebates-elementals {
            padding: 0 3rem 0 0;
        }

        .confirm-rebate-response-button {
            display: flex;
            justify-content: flex-end;

            @include mobile {
                justify-content: center;
            }
        }

        .confirm-rebates-print {
            text-align: right;
            .confirm-print-button {
                background-color: $white;
                border: none;
                box-shadow: none;
                &:hover {
                    transform: scale(1.07);
                    cursor: pointer;
                }
            }
        }

        @media print {
            display: none;
        }
    }
}
