.brand-lincoln {
    .search-bar {
        .banner {
            margin-top: 76px;
            .top {
                @include mobile {
                    height: 500px;
                }
            }
            .bottom-container {
                margin-top: 16px;
                padding-bottom: 16px;
                @include mobile {
                    margin-top: -30px;
                }

                @include tablet {
                    margin-top: -75px;
                }
            }
        }
    }
}
