@import '../../../../../styles/lincoln/main.scss';

.brand-lincoln {
    .choose_rebates_form {
        max-width: 102.4rem;
        margin: 0 auto;
        padding: 0;
        align-content: center;
        @include tablet {
            padding: 0 2rem;
        }
        .search-over-button {
            margin-top: 10px;
            margin-bottom: 15px;
            padding-left: 8%;
            @include desktop {
                position: absolute;
                right: 100px;
                bottom: 50px;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .rebate-offers-page {
            display: block;
            position: relative;
            width: 100%;
            text-align: center;
            margin-top: 40px;
            @include desktop {
                margin-top: 62px;
            }

            .choose-method-page-description {
                height: auto;
                width: auto;
                text-align: center;
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 26px;
                padding: 0 17px 0 17px;
                margin-bottom: 24px;
                @include desktop {
                    padding: 0 10rem 0 10rem;
                }
            }
            .choose-method-tip-text {
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 16px;
                letter-spacing: 1px;
                line-height: 26px;
                text-align: center;
                padding-bottom: 32px;
                margin-left: 4%;
                margin-right: 4%;
                border-bottom: 1px solid $border-grey;
                font-weight: bold;
                @include desktop {
                    height: 28px;
                    padding-bottom: 4rem;
                    padding-inline: 10rem;
                    margin-left: 8%;
                    margin-right: 8%;
                    padding-top: 0;
                }
            }
            .choose-method-dealer-info-text {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
                font-size: 18px;
                line-height: 24px;
                margin: 2rem 4% 55px 4%;
                text-align: left;
                padding-bottom: 15px;
                letter-spacing: 0;

                @include desktop {
                    margin: 2rem 8% 2rem 8%;
                }

                .text-bold {
                    color: $black;
                    font-weight: bolder;
                }
            }
            .choose-rebates-table {
                flex-direction: column;
                margin: 0;
                padding: 0;

                @include tablet {
                    margin: 0;
                    padding: 0;
                }

                @include desktop {
                    margin: auto auto 2rem;
                    flex-direction: row;
                    display: flex;
                    margin-left: 8%;
                }
                .choose-rebates-table-column {
                    margin: 1rem 1rem 1rem 4%;
                    text-align: left;
                    @include desktop {
                        width: 50%;
                        margin: 1rem 1rem 1rem 0;
                    }
                    .ford-pass-rewards-point {
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                        text-transform: uppercase;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 1.33px;
                        line-height: 32px;
                        text-align: left;
                        margin-bottom: 19px;
                        @include desktop {
                            height: 50px;
                            width: auto;
                        }
                        @include l-desktop {
                            height: auto;
                        }
                    }
                    .individual-rebate-offers {
                        margin-bottom: 24px;
                        .debit-card-radio-button-label {
                            font-family: $f1-font-regular;
                            font-size: 16px;
                            letter-spacing: 1px;
                            line-height: 26px;
                            text-align: center;
                            .rebate-names {
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                                padding-left: 5px;
                                height: 24px;
                                font-size: 18px;
                                letter-spacing: 0;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
            .legal-regular {
                font-family: $f1-font-light;
                font-size: 0.9rem;
                line-height: 1.8rem;
                letter-spacing: 0;
                -webkit-font-smoothing: antialiased;
                text-align: left;
                .disclaimer-text {
                    margin-left: 4%;
                    margin-right: 4%;
                    margin-top: 8px;
                    margin-bottom: 2rem;
                    text-align: justify;
                    @include desktop {
                        margin-left: 8%;
                        margin-right: 8%;
                    }
                }
            }
            .no-rebates-error-message {
                font-family: $f1-font-regular;
                width: 100%;
                color: $error-text;
                margin: 1rem 0;
                letter-spacing: 1px;
                font-size: 12px;
                line-height: 12px;
                text-align: left;
                padding-left: 8%;
                text-transform: capitalize;
            }
            .error-display {
                padding-bottom: 4rem;
            }
            .no-available-offers-error {
                font-family: $proxima-nova-regular;
                width: 100%;
                color: $brand-secondary;
                padding-left: 8%;
                text-align: left;
                text-transform: capitalize;
                height: 26px;
                font-size: 18px;
                letter-spacing: 1.13px;
                line-height: 26px;
            }
            .continue-button {
                margin-bottom: 3em;
                @include mobile {
                    display: flex;
                    justify-content: center;
                }
                @include tablet {
                    padding-left: 40%;
                }
                @include desktop {
                    padding-left: 83%;
                }
                @include md-desktop {
                    padding-left: 84%;
                }
                @include fds-large {
                    padding-left: 86%;
                }
                .primary-button {
                    background-color: $brand-secondary;
                }
            }
            .title-button {
                display: block;
                margin-left: 4%;
                text-align: left;
                @include desktop {
                    display: flex;
                    flex: 1;
                    left: 0;
                    margin-left: 8%;
                    margin-bottom: 74px;
                }
                .group-titles {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                    font-size: 18px;
                    letter-spacing: 0;
                    line-height: 24px;
                    font-weight: bold;
                    @include tablet {
                        height: auto;
                        width: auto;
                    }
                }
                .clear-button {
                    margin-top: 35px;
                    margin-bottom: 58px;
                    @include desktop {
                        margin-top: 0;
                        margin-bottom: 0;
                        position: absolute;
                        right: 8%;
                    }
                    .primary-button {
                        box-sizing: border-box;
                        border: 2px solid $brand-secondary;
                        .button-label {
                            color: $brand-secondary;
                            font-family: $proxima-nova-semi-bold;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 20px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
