.brand-ford {
    .check-rebates-formft {
        background-color: $white;
        padding: 3rem 0 0 0;
        .check-rebate-statusft {
            text-align: center;
            color: $fds-color-dk-gray;
            margin: 0 1rem;
            .form__titleft {
                margin: 0 0 1.1rem 0;
                text-transform: uppercase;
                font-size: 24px;
                line-height: 34px;
                font-family: $f1-font-light;
            }
            .form-textft {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                .form-linkft {
                    color: $fds-color-dk-gray;
                }
            }
        }
        .l-formft {
            max-width: 1440px;
            margin: 0 auto;
            @include desktop {
                padding: 3rem 5rem;
            }

            @include tablet {
                padding: 3rem 0;
            }

            @include mobile {
                padding: 3rem 0;
            }
            .form_elementsft {
                margin: auto auto 2rem;
                max-width: 102.4rem;

                @include mobile {
                    margin: 0;
                    padding: 0;
                }

                @include tablet {
                    padding: 0 2rem;
                }

                @include desktop {
                    padding: 0 5rem;
                }

                .warningft {
                    font-family: $f1-font-regular;
                    width: 100%;
                    color: $error-text;
                    margin: 1rem 0;
                    font-size: 12px;
                    line-height: 12px;
                    text-align: center;
                    text-transform: capitalize;
                }

                .service-rebates-rowft {
                    flex-direction: row;
                    display: flex;
                    max-width: 102.4rem;

                    @include mobile {
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                    }

                    @include tablet {
                        margin: 0;
                        padding: 0;
                    }

                    @include desktop {
                        margin: auto 15% 2rem 19%;
                        padding: 0 2rem;
                    }

                    .row-elementsft {
                        flex: 2;
                        .form__divft {
                            position: relative;
                            .form__inputft {
                                font-family: $f1-font-regular;
                                font-size: 16px;
                                line-height: 26px;
                                padding: 15px;
                                width: 100%;
                                height: 50px;
                                border-radius: 0.2rem;
                                border: 0.1rem solid $fds-color-border-gray;
                                transition: all 0.3s ease-in-out;
                                &:focus {
                                    box-shadow: $outer-drop-shadow;
                                    border: 1.5px solid $black;
                                }
                                &:focus + .form__labelft {
                                    top: -15px;
                                    left: 0;
                                    font-family: $f1-font-regular;
                                    font-size: 12px;
                                    line-height: 12px;
                                }
                                &:not(:placeholder-shown)
                                    + .form__labelft:not(:focus) {
                                    top: -15px;
                                    left: 0;
                                    font-family: $f1-font-regular;
                                    font-size: 12px;
                                    line-height: 12px;
                                }
                                &.invalid {
                                    border: 0.1rem solid $fds-color-red;
                                }
                            }
                            .form__labelft {
                                position: absolute;
                                left: 15px;
                                top: 50%;
                                pointer-events: none;
                                transform: translateY(-50%);
                                color: $fds-color-dk-gray;
                                font-family: $f1-font-regular;
                                transition: all 0.3s ease-out;
                                &.invalid {
                                    color: $fds-color-red;
                                }
                            }
                        }
                        .form__errorft {
                            font-family: $f1-font-regular;
                            width: 100%;
                            color: $error-text;
                            margin: 1rem 0;
                            font-size: 12px;
                            line-height: 12px;
                        }
                        .secondary-button {
                            padding-top: 30px;
                            .secondary-button-link {
                                color: $dark-blue;
                                padding: 19px 0;
                                .secondary-button-text {
                                    text-align: left;
                                    text-decoration: underline;
                                    font-family: $f1-font-regular;
                                    font-size: 16px;
                                    line-height: 26px;
                                }
                                &:hover {
                                    color: $light-blue;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .row-buttonft {
                        flex: 1;
                        @include mobile {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
                .service-rebates-rowft > * {
                    width: auto;
                    width: 100%;
                    box-sizing: border-box;

                    @include mobile {
                        padding: 1.5rem;
                    }

                    @include tablet {
                        padding: 1.5rem 1rem;
                    }
                }
            }
        }
    }

    .success-banner {
        max-width: 1440px;
        margin: auto;
        text-align: center;

        @include mobile {
            padding: 5rem 0;
        }

        @include tablet {
            padding: 5rem 0;
        }

        @include desktop {
            padding: 4rem 5rem;
        }

        .success-message {
            .success-header {
                font-family: $f1-font-light;
                color: $fds-color-dk-gray;
                font-size: 24px;
                line-height: 34px;
                text-transform: uppercase;
            }
            .success-info {
                font-size: 16px;
                line-height: 26px;
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;

                @include mobile {
                    padding: 1rem 1rem;
                }

                @include tablet {
                    padding: 1rem 2rem;
                }

                @include desktop {
                    padding: 1rem 7rem;
                }
            }
            .button {
                margin: 2rem 0;
                display: flex;
                justify-content: center;
            }
        }
    }
}
